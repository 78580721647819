import { ApolloClient, InMemoryCache } from "@apollo/client";

export function getApolloClient(token: string | null) {
  const uri = process.env.REACT_APP_BASE_URL;
  console.log(uri);
  // const uri = "https://graph-stage.l192.com";
  // const uri = "https://graph.lf.groupincorp.com";
  // const uri = "http://localhost:4000";

  const client = new ApolloClient({
    uri: token ? uri + "?token=" + token : uri,
    cache: new InMemoryCache(),
  });

  return client;
}
