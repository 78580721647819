/* eslint-disable react-hooks/exhaustive-deps */
import { ApolloProvider, gql, useQuery } from "@apollo/client";
import React, { Suspense, useContext, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { getApolloClient } from "./lib/ApolloClient";
import { AuthenticatedRouter, UnauthenticatedRouter } from "./router";
import "./style/index.css";
import { ChakraProvider } from "@chakra-ui/react";
import { TokenContext } from "./lib/context/TokenContext";
import { UserContext } from "./lib/context/UserContext";
import { AppProvider } from "@shopify/polaris";
import en from "@shopify/polaris/locales/en.json";
import "@shopify/polaris/build/esm/styles.css";
import { SupplierContext } from "./lib/context/SupplierContext";
import { ModalComponent } from "./components/@shopify/ModalComponent";
import { Modal } from "./hook/modal";

const QUERY = gql`
  query admin {
    admin {
      id
      name
      username
      image
      superAdmin
      active
      role
      partnerPermission
      shellStoreGroup
    }
  }
`;

const QUERY_SUPP = gql`
  query supplierListFromConsolidate(
    $filter: SupplierOrderListFilters
    $search: SupplierOrderListSearch
  ) {
    supplierListFromConsolidate(filter: $filter, search: $search) {
      id
      supplier {
        id
        name
      }
      order
    }
  }
`;

function VerifyApp() {
  const { token } = useContext(TokenContext);
  const { data, loading } = useQuery(QUERY);

  const [supplier, setSupplier] = useState([]);
  useQuery(QUERY_SUPP, {
    fetchPolicy: "no-cache",
    variables: {
      filter: {
        status: "TO_PREPARE",
      },
    },
    onCompleted: (res) => {
      setSupplier(
        res.supplierListFromConsolidate.map((x: any) => x.supplier.id)
      );
    },
  });

  return (
    <UserContext.Provider value={{ user: data ? data.admin : null }}>
      <ApolloProvider client={getApolloClient(token)}>
        <SupplierContext.Provider
          value={{
            supplier,
            setSupplier,
          }}
        >
          <ModalComponent ref={t => Modal.setModal(t)}/>
          <Suspense fallback={<div>Loading...</div>}>
            <BrowserRouter>
              {loading && !data && <div>Loading...</div>}
              {data && data.admin && data.admin.shellStoreGroup >= 0 ? (
                <AuthenticatedRouter />
              ) : (
                <UnauthenticatedRouter />
              )}
            </BrowserRouter>
          </Suspense>
        </SupplierContext.Provider>
      </ApolloProvider>
    </UserContext.Provider>
  );
}

function App() {
  const [token, setToken] = useState(localStorage.getItem("token"));
  console.log(process.env.REACT_APP_BASE_URL);
  return (
    <AppProvider i18n={en}>
      <ChakraProvider>
        <TokenContext.Provider value={{ token, setToken }}>
          <ApolloProvider client={getApolloClient(token)}>
            <VerifyApp />
          </ApolloProvider>
        </TokenContext.Provider>
      </ChakraProvider>
    </AppProvider>
  );
}

export default App;
