import React, { CSSProperties, PropsWithChildren } from "react";
import { Sidebar } from "../Sidebar";
import { Loading, Frame, Page } from "@shopify/polaris";
import { NavigationDesktop } from "../@shopify/NavigationDesktop";
import { TopbarMarkup } from "../@shopify/TopbarMarkup";

export function LayoutWithSidebar(
  props: PropsWithChildren<{
    title?: string;
    style?: CSSProperties;
    loading?: boolean;
    fullWidth?: boolean;
  }>
) {
  const shopify = true;
  const onClickToggle = () => {
    let sidebar: any = document.querySelector(".sidebar");
    sidebar.classList.toggle("close");
  };

  if (shopify) {
    return (
      <Frame
        navigation={<NavigationDesktop />}
        logo={{
          topBarSource: "/l192.png",
          url: "/",
          width: 150,
        }}
        topBar={<TopbarMarkup />}
      >
        {!!props.loading && <Loading />}
        <Page title={props.title} fullWidth={props.fullWidth}>
          {props.children}
        </Page>
      </Frame>
    );
  }

  return (
    <div>
      <Sidebar />
      <div className="page">
        <div className="top">
          <i className="bx bx-menu toggle" onClick={onClickToggle}></i>
        </div>
        {!!props.loading && (
          <Frame>
            <Loading />
          </Frame>
        )}
        <div style={props.style}>
          <h3>{props.title}</h3>
          {props.children}
        </div>
      </div>
    </div>
  );
}
