import moment from 'moment';

export default class Formatter {
  static currency(value: string | number | undefined | null) {
    return (
      '$' +
      Number(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  }

  static number(value: string | number | undefined | null) {
    return Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  static timestampTimeAgo(ts: number) {
    return moment(ts * 1000).fromNow();
  }

  static beautifyUglyName(name?: string | null) {
    if (!name) return '';

    // Replace ugly dash with space
    let beautyName = name.replaceAll('_', ' ');

    // Upper case first
    beautyName = beautyName
      .toLowerCase()
      .split(' ')
      .map(word => {
        if (word.length === 0) return '';
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');

    return beautyName;
  }
}
