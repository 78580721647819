import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import OrderDetailPage from "../page/order/detail";

const ErrorPage = React.lazy(() => import("../page/contact/ErrorPage"));
const OrderPage = React.lazy(() => import("../page/order/index"));
const LoginPage = React.lazy(() => import("../page/auth/login"));

export const useLocationChange = (action: (loc: Location | any) => void) => {
  const location = useLocation();

  React.useEffect(() => {
    action(location);
  }, [location, action]);
};

export function UnauthenticatedRouter() {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} errorElement={<ErrorPage />} />
      <Route path="*" element={<ErrorPage />} errorElement={<ErrorPage />} />
    </Routes>
  );
}

export function AuthenticatedRouter() {
  return (
    <Routes>
      <Route
        path="/page/order"
        element={<OrderPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/page/order/:id"
        element={<OrderDetailPage />}
        errorElement={<ErrorPage />}
      />
      <Route path="/" element={<OrderPage />} errorElement={<ErrorPage />} />
      <Route path="*" element={<ErrorPage />} errorElement={<ErrorPage />} />
    </Routes>
  );
}
