import { createContext } from "react";

type SupplierContextType = {
  supplier: any;
  setSupplier: any;
};

export const SupplierContext = createContext<SupplierContextType>({
  supplier: null,
  setSupplier: null,
});
