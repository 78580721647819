import { useParams } from "react-router-dom";
import { LayoutWithSidebar } from "../../components/layout/LayoutWithSidebar";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Badge,
  Banner,
  Button,
  DataTable,
  IndexTable,
  Layout,
  LegacyCard,
  SkeletonBodyText,
  Text,
  Thumbnail,
  useIndexResourceState,
} from "@shopify/polaris";
import CustomerOrderStatusBadge from "../../page/order/components/CustomerOrderStatusBadge";
import { useCallback, useRef, useState } from "react";
import { Graph } from "../../generated/graph";
import Formatter from "../../lib/Formatter";
import {
  PrintMinor,
  MetaobjectMinor,
  RiskMinor,
  MarkPaidMinor,
} from "@shopify/polaris-icons";
import { PrinterQR } from "../../page/order/components/PrinterQR";
import { Modal } from "../../hook/modal";

const CHANGE_CUSTOMER_ORDER_ITEM_STATUS = gql`
  mutation changeCustomerOrderItemStatus(
    $orderId: String
    $uuid: String
    $status: CustomerOrderItemStatus!
  ) {
    changeCustomerOrderItemStatus(
      uuid: $uuid
      orderId: $orderId
      status: $status
    ) {
      success
      assignedSlot
    }
  }
`;

const QUERY = gql`
  query supplierOrderListV2(
    $filter: SupplierOrderListFilters
    $page: PaginationInput
    $search: SupplierOrderListSearch
  ) {
    supplierOrderListV2(filter: $filter, page: $page, search: $search) {
      instruction
      data {
        supplier {
          name
        }
        orderId
        date
        status
        totalAmount
        items {
          id
          price
          priceBeforeDiscount
          status
          deleted
          uuid
          order {
            address {
              address
            }
          }
          sku {
            option1
            option2
            product {
              thumbnail
              code
            }
          }
          deliveryFee
        }
      }
    }
  }
`;

const banner_init =(toital: number = 0, success: number = 0)=> ({
  'info': {
    title: 'Order has updated their status',
    description: 'Make sure you know how these changes affect your order.'
  },
  'warning': {
    title: 'Some of order number cannot change status:',
    description: `Total selected update status was ${toital} but succeeded ${success}.`
  },
  'critical': {
    title: 'Fail change status',
    description: 'All of order number selected cannot change'
  },
  'success': {
    title: 'Order status was changed',
    description: ''
  }
})

export function OrderDetailScreen() {
  const { id } = useParams();
  const refPrint = useRef<HTMLButtonElement | null>(null);
  const [items, setItems] = useState<Graph.CustomerOrderItemV2[]>([]);
  const [banner, setBanner] = useState<{
    total: number;
    success: number;
    status: "info" | "warning" | "critical" | 'success';
    open: boolean;
  }>({
    total: 0,
    success: 0,
    status: "info",
    open: false,
  });
  const [changeCustomerOrderItemStatus, { loading: loading_mutation }] =
    useMutation(CHANGE_CUSTOMER_ORDER_ITEM_STATUS, {
      refetchQueries: ["supplierOrderList", "supplierOrderListV2"],
    });
  const { data, loading } = useQuery(QUERY, {
    skip: !id,
    fetchPolicy: "no-cache",
    variables: {
      search: {
        orderId: id,
      },
      page: {
        limit: 1,
        offset: 0,
        cacheTotalRow: undefined,
      },
    },
    onCompleted: (res) => {
      if (res && res.supplierOrderListV2.data.length > 0) {
        setItems(res.supplierOrderListV2.data[0].items);
      }
    },
  });

  const resourceIDResolver: any = (resource: any) => {
    return resource.uuid;
  };

  const {
    selectedResources,
    handleSelectionChange,
    allResourcesSelected,
  } = useIndexResourceState(items as any, {
    resourceIDResolver,
  });

  const onChangeCustOrderStatus = useCallback(
    (status: "PREPARED" | "OOS" | "REQUEST_PICKUP") => {
      Modal.dialog({
        title: "Confirmation",
        body: (
          <Text as="p" variant="bodyMd">
            <span>
              {Formatter.beautifyUglyName(status)} on number{" "}
              {status !== "REQUEST_PICKUP"
                ? items
                    .filter((x) => !!selectedResources.includes(x.id as any))
                    .map((x) => x.uuid)
                    .join(",")
                : data.supplierOrderListV2.data[0].orderId}
              .
            </span>
          </Text>
        ),
        buttons: [
          {
            title: "Yes",
            class: "primary",
            onPress: async () => {
              if (status === "REQUEST_PICKUP") {
                const res = await changeCustomerOrderItemStatus({
                  variables: {
                    orderId: String(data.supplierOrderListV2.data[0].orderId),
                    status,
                  },
                });
                setBanner({
                  total: selectedResources.length,
                  success: selectedResources.length,
                  status: res.data.changeCustomerOrderItemStatus.success ? "success" : "critical",
                  open: true,
                });
                return;
              } else {
                let i = 0;
                for (const s of selectedResources) {
                  const res = await changeCustomerOrderItemStatus({
                    variables: {
                      uuid: String(s),
                      status,
                    },
                  });

                  if (res.data.changeCustomerOrderItemStatus.success) {
                    i = i + 1;
                    handleSelectionChange("single" as any, false, s as any);
                  }
                }
                setBanner({
                  total: selectedResources.length,
                  success: i,
                  status: selectedResources.length === i ? "info" : i === 0 ? "critical" : "warning",
                  open: true,
                });
              }
            },
          },
          {
            title: "No",
            class: "",
          },
        ],
      });
    },
    [items, data.supplierOrderListV2.data, selectedResources, changeCustomerOrderItemStatus, handleSelectionChange],
  );

  const handlePrint = () => {
    refPrint.current?.click();
  };

  const handlePrepared = () => onChangeCustOrderStatus("PREPARED");
  const handleOOS = () => onChangeCustOrderStatus("OOS");
  const handleRequestPickup = () => onChangeCustOrderStatus("REQUEST_PICKUP");

  if (loading || !data) {
    return (
      <LayoutWithSidebar
        title={`Orders Detail`}
        loading={loading}
      ></LayoutWithSidebar>
    );
  }

  const prepared = items.filter(
    (f) => f.status === "PREPARED" && selectedResources.includes(f.id as any)
  ).length;
  const oos = items.filter(
    (f) => f.status === "OOS" && selectedResources.includes(f.id as any)
  ).length;
  const request_pickup = items.filter(
    (f) =>
      f.status === "REQUEST_PICKUP" && selectedResources.includes(f.id as any)
  ).length;

  return (
    <LayoutWithSidebar title={`Orders Detail`} loading={false}>
      <Layout>
        <Layout.Section>
          <LegacyCard>
            <LegacyCard.Section>
              <div style={{ display: "flex" }}>
                {data && items.length > 0 && (
                  <div className="mr-2">
                    <Button
                      size="slim"
                      icon={PrintMinor as any}
                      onClick={handlePrint}
                    >
                      Print
                    </Button>
                  </div>
                )}
                {data &&
                  items.length > 0 &&
                  data.supplierOrderListV2.data[0].status === "TO_PREPARE" && (
                    <div className="mr-2">
                      <Button
                        size="slim"
                        disabled={
                          selectedResources.length === 0 ||
                          loading_mutation ||
                          prepared + oos + request_pickup > 0
                        }
                        icon={MetaobjectMinor as any}
                        primary
                        onClick={handlePrepared}
                        loading={loading_mutation}
                      >
                        Prepared
                      </Button>
                    </div>
                  )}
                {data &&
                  items.length > 0 &&
                  data.supplierOrderListV2.data[0].status === "TO_PREPARE" && (
                    <div className="mr-2">
                      <Button
                        size="slim"
                        disabled={
                          selectedResources.length === 0 ||
                          loading_mutation ||
                          prepared + oos + request_pickup > 0
                        }
                        destructive
                        icon={RiskMinor as any}
                        onClick={handleOOS}
                        loading={loading_mutation}
                      >
                        OOS
                      </Button>
                    </div>
                  )}
                {data &&
                  items.length > 0 &&
                  data.supplierOrderListV2.data[0].status === "PREPARED" && (
                    <div className="mr-2">
                      <Button
                        size="slim"
                        disabled={loading_mutation}
                        icon={MarkPaidMinor as any}
                        primary
                        onClick={handleRequestPickup}
                        loading={loading_mutation}
                      >
                        Request Pickup
                      </Button>
                    </div>
                  )}
              </div>
            </LegacyCard.Section>
            <LegacyCard.Section>
              {loading || !data ? (
                <div style={{ width: 200 }}>
                  <SkeletonBodyText lines={3} />
                </div>
              ) : (
                <>
                  <Text variant="bodyMd" as="p" fontWeight="bold">
                    #{data.supplierOrderListV2.data[0].orderId}
                  </Text>
                  <div className="mt-2 mb-2">
                    <CustomerOrderStatusBadge
                      status={data.supplierOrderListV2.data[0].status}
                    />
                  </div>
                  {!!banner.open && (
                  <div>
                    <Banner
                      title={banner_init()[banner.status].title}
                      status={banner.status}
                      onDismiss={() => setBanner({ ...banner, open: false })}
                    >
                      <p>
                        {banner_init(banner.total, banner.success)[banner.status].description}
                      </p>
                    </Banner>
                    <br />
                  </div>
                )}
                  <Text variant="bodySm" as="p" color="subdued">
                    Order at {data.supplierOrderListV2.data[0].date}
                  </Text>
                </>
              )}
            </LegacyCard.Section>
            <LegacyCard.Section flush>
              <IndexTable
                selectable={
                  data
                    ? data.supplierOrderListV2.data[0].status !== "PREPARED"
                    : false
                }
                headings={[
                  { title: "#" },
                  { title: "" },
                  { title: "Description" },
                  { title: "Status" },
                  { title: "Price" },
                ]}
                itemCount={items.length}
                loading={loading || !data}
                selectedItemsCount={
                  allResourcesSelected ? "All" : selectedResources.length
                }
                onSelectionChange={handleSelectionChange}
              >
                {items.map((item: any, index: number) => {
                  return (
                    <IndexTable.Row
                      position={item.uuid}
                      id={item.uuid}
                      key={index}
                      selected={selectedResources.includes(item.uuid)}
                    >
                      <IndexTable.Cell>
                        <Text as="p" variant="bodySm">
                          {item.uuid}
                        </Text>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <Thumbnail
                          source={item.sku.product.thumbnail}
                          alt=""
                          size="small"
                        />
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <small>
                          <b>{item.sku.product.code}</b>
                          <Text variant="bodySm" as="p" color="subdued">
                            {item.sku.option1} | {item.sku.option2}
                          </Text>
                        </small>
                      </IndexTable.Cell>
                      <IndexTable.Cell
                        className={item.deleted ? "table-cell-strike" : ""}
                      >
                        <Badge>
                          {item.deleted
                            ? "Deleted"
                            : Formatter.beautifyUglyName(item.status)}
                        </Badge>
                      </IndexTable.Cell>
                      <IndexTable.Cell
                        className={item.deleted ? "table-cell-strike" : ""}
                      >
                        <Text as="p" variant="bodySm" fontWeight="bold">
                          {Formatter.currency(item.price)}
                        </Text>
                        {Number(item.priceBeforeDiscount || 0) >
                          Number(item.price || 0) && (
                          <Text as="p" variant="bodySm" color="subdued">
                            <span style={{ textDecoration: "line-through" }}>
                              {Formatter.currency(item.priceBeforeDiscount)}
                            </span>
                          </Text>
                        )}
                      </IndexTable.Cell>
                    </IndexTable.Row>
                  );
                })}
              </IndexTable>
            </LegacyCard.Section>
            {data && items.length > 0 && (
              <LegacyCard.Section title="Delivery Address">
                <Text variant="bodySm" as="p" color="subdued">
                  {data.supplierOrderListV2.data[0].items[0].order.address
                    ? data.supplierOrderListV2.data[0].items[0].order.address
                        .address || "No address"
                    : "No address"}
                </Text>
              </LegacyCard.Section>
            )}
          </LegacyCard>
        </Layout.Section>
        {data && items.length > 0 && (
          <Layout.Section oneThird>
            <LegacyCard title="Summary">
              <LegacyCard.Section flush>
                <DataTable
                  headings={["Description", "Amount"]}
                  columnContentTypes={["text", "numeric"]}
                  totalsName={{
                    singular: "Grand Total",
                    plural: "Grand Total",
                  }}
                  totals={[
                    "",
                    Formatter.currency(
                      Number(data.supplierOrderListV2.data[0].totalAmount)
                    ),
                  ]}
                  rows={[
                    [
                      "Total",
                      Formatter.currency(
                        data.supplierOrderListV2.data[0].totalAmount
                      ),
                    ],
                  ]}
                />
              </LegacyCard.Section>
            </LegacyCard>
            <LegacyCard title="QR Code">
              {data && items.length > 0 ? (
                <LegacyCard.Section flush>
                  <div className="pl-2 pr-2">
                    <PrinterQR
                      codes={data.supplierOrderListV2.data[0].orderId}
                      refButton={refPrint}
                    />
                  </div>
                </LegacyCard.Section>
              ) : (
                <LegacyCard.Section></LegacyCard.Section>
              )}
            </LegacyCard>
          </Layout.Section>
        )}
      </Layout>
    </LayoutWithSidebar>
  );
}
