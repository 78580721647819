import { OrdersMinor } from "@shopify/polaris-icons";
interface Props {
  text: string;
  to: string;
  icon: any;
  subs?: {
    text: string;
    to: string;
  }[];
}

interface MenuProps {
  section: string;
  items: Props[];
}

export const MenuItems: MenuProps[] = [
  {
    section: "Applications",
    items: [
      {
        text: "Orders",
        to: "/page/order",
        icon: OrdersMinor,
      },
    ],
  },
  // {
  //   text: "Category",
  //   to: "#",
  //   icon: "bx bxs-collection",
  //   subs: [
  //     {
  //       text: "HTML & CSS",
  //       to: "#",
  //     },
  //     {
  //       text: "Javascript",
  //       to: "#",
  //     },
  //   ],
  // },
];
