import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TokenContext } from "../lib/context/TokenContext";
import { UserContext } from "../lib/context/UserContext";

function Profile() {
  const navigate = useNavigate();
  const { setToken } = useContext(TokenContext);
  const { user } = useContext(UserContext);

  const onClickLogout = () => {
    localStorage.removeItem("token");
    setToken(null);
    navigate("/");
  };

  return (
    <div className="profile_details">
      <div className="profile_content">
        <img src={user ? user.image : ""} alt="profile" />
      </div>
      <div className="name_job">
        <div className="_job">
          <div className="profile_name">{user ? user.name : ""}</div>
          <div className="job">{user ? user.role : ""}</div>
        </div>
      </div>
      <i
        className="bx bx-log-out"
        style={{ cursor: "pointer" }}
        onClick={onClickLogout}
      ></i>
    </div>
  );
}

export class Sidebar extends React.Component<{}> {
  constructor(props: any) {
    super(props);
    this.onClickDropdown = this.onClickDropdown.bind(this);
  }

  componentDidMount(): void {
    const arrow = document.querySelectorAll(".arrow");
    if (arrow.length > 0) {
      for (let i = 0; i < arrow.length; i++) {
        arrow[i].addEventListener("click", this.onClickDropdown);
      }
    }
  }

  onClickDropdown(e: any) {
    let arrowParent = e.target.parentElement.parentElement;
    arrowParent.classList.toggle("showMenu");
  }

  render(): React.ReactNode {
    return (
      <div className="sidebar">
        <div className="logo_details">
          <i>
            <img src="https://www.l192.com/images/logo2.png" alt="" />
          </i>
          <span className="logo_name">L192-Consolidate</span>
        </div>
        <ul className="nav_links">
          {/* {MenuItems.map((item, index) => {
            return (
              <li key={index}>
                <div className={item.subs ? "iocn_link" : ""}>
                  <Link to={item.to}>
                    <i className={item.icon}></i>
                    <span className="link_name">{item.text}</span>
                  </Link>
                  {item.subs ? (
                    <i className="bx bxs-chevron-down arrow"></i>
                  ) : (
                    <></>
                  )}
                </div>
                <ul className={item.subs ? "sub_menu" : "sub_menu blank"}>
                  <li>
                    <Link to={item.to} className="link_name">
                      {item.text}
                    </Link>
                  </li>
                  {item.subs &&
                    item.subs.map((x, i) => {
                      return (
                        <li key={i}>
                          <Link to={x.to}>{x.text}</Link>
                        </li>
                      );
                    })}
                </ul>
              </li>
            );
          })} */}
          <li>
            <Profile />
          </li>
        </ul>
      </div>
    );
  }
}
