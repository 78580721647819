import React, { useCallback, useContext, useState } from "react";
import { IconableAction, TopBar } from "@shopify/polaris";
import { LogOutMinor } from "@shopify/polaris-icons";
import { UserContext } from "../../lib/context/UserContext";
import { useNavigate } from "react-router-dom";
import { TokenContext } from "../../lib/context/TokenContext";

export function TopbarMarkup() {
  const navigate = useNavigate();
  const { setToken } = useContext(TokenContext);
  const { user } = useContext(UserContext);
  const [userMenuActive, setUserMenuActive] = useState(false);

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );

  const onClickLogout = () => {
    localStorage.removeItem("token");
    setToken(null);
    navigate("/");
  };

  const userMenuAction: { items: IconableAction[] }[] = [
    {
      items: [
        {
          content: "Log Out",
          icon: LogOutMinor as any,
          onAction: onClickLogout,
        },
      ],
    },
  ];

  const userMenuMarkup = (
    <TopBar.UserMenu
      name={user.name}
      actions={userMenuAction}
      detail={user.role}
      initials={String(user.name)
        .split(" ")
        .map((x) => x.charAt(0).toUpperCase())
        .join("")}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  return <TopBar showNavigationToggle userMenu={userMenuMarkup} />;
}
