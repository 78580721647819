import React from "react";
import { Badge } from "@shopify/polaris";
import { Graph } from "../../../generated/graph";
import Formatter from "../../../lib/Formatter";

export default function CustomerOrderStatusBadge({
  status,
}: {
  status?: Graph.SupplierOrderListStatus | null;
}) {
  let progress: "incomplete" | "partiallyComplete" | "complete" = "incomplete";
  let badgeStatus:
    | "info"
    | "success"
    | "warning"
    | "critical"
    | "attention"
    | "new"
    | undefined = undefined;

  if (status === "COMPLETED") {
    progress = "complete";
    badgeStatus = "success";
  }

  if (status === "REQUESTED_PICKUP") {
    progress = "partiallyComplete";
    badgeStatus = "warning";
  }

  if (status === "READY") {
    progress = "partiallyComplete";
    badgeStatus = "attention";
  }

  if (status === "TO_PREPARE") {
    badgeStatus = "new";
  }

  if (status === "PREPARED") {
    badgeStatus = "warning";
  }

  if (!status) {
    return null;
  }

  return (
    <Badge status={badgeStatus} progress={progress}>
      {Formatter.beautifyUglyName(status?.toString())}
    </Badge>
  );
}
