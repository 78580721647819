import { Navigation } from "@shopify/polaris";
import { MenuItems } from "../../MenuItem";
import { gql, useQuery } from "@apollo/client";
import { Graph } from "../../generated/graph";
import { useContext } from "react";
import { SupplierContext } from "../../lib/context/SupplierContext";

const QUERY = gql`
  query supplierOrderListV2(
    $filter: SupplierOrderListFilters
    $page: PaginationInput
    $search: SupplierOrderListSearch
  ) {
    supplierOrderListV2(filter: $filter, page: $page, search: $search) {
      pagination {
        current
        size
        total
      }
    }
  }
`;

export function NavigationDesktop() {
  const { supplier } = useContext(SupplierContext);

  const { data, loading } = useQuery<Graph.Query>(QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      filter: {
        status: "TO_PREPARE",
        supplierId: supplier,
      },
    },
  });

  return (
    <Navigation location="/">
      {MenuItems.map((menu) => {
        return (
          <Navigation.Section
            key={menu.section}
            title={menu.section}
            items={menu.items.map((item) => {
              if (item.text === "Orders") {
                return {
                  label: item.text,
                  url: item.to,
                  icon: item.icon as any,
                  badge:
                    !loading &&
                    data &&
                    (data.supplierOrderListV2?.pagination?.total === 0
                      ? null
                      : String(data.supplierOrderListV2?.pagination?.total)),
                };
              }
              return {
                label: item.text,
                url: item.to,
                icon: item.icon as any,
              };
            })}
          />
        );
      })}
    </Navigation>
  );
}
