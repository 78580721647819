import React, { useRef, useState } from "react";
import { QRCode } from "react-qr-svg";
interface Props {
  codes: string;
  refButton?: React.RefObject<HTMLButtonElement>;
  title?: string;
  supplier?: {
    id: string;
    name: string;
  };
}

export function PrinterQR(props: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [doc, setDoc] = useState("");

  const onPrintClicked = () => {
    if (ref.current && iframeRef.current) {
      setDoc(
        `<style type="text/css">
          @import url(https://fonts.googleapis.com/css?family=Nokora&display=swap);
        </style>
        <style>
          html, body { 
            margin: 0; 
            padding: 0; 
            text-align: center; 
            color: '#3E4B5B'; 
            font-family: "Nokora", "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
          } 
          
          @media print { 
            @page {
              margin: 0;
            }

            .pagebreak { 
              clear: both !important; 
              page-break-after: always; 
              page-break-before: always !important; 
            } 
            
            .center { 
              display: flex; 
              justify-content: center; 
              flex-direction: column; 
              align-items:center;
              font-size: 11.52px;
            }
          }
        </style>
        <div>` +
          ref.current.innerHTML +
          "</div><script>window.print(); /*" +
          Math.random().toString() +
          "*/</script>"
      );
    }
  };

  return (
    <div>
      <div ref={ref}>
        <div className="pagebreak">
          <div className="center">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div style={{ width: "100%", padding: 10, textAlign: "left" }}>
                <small style={{ fontSize: "11.52px" }}>
                  Sender: {props.supplier?.name}
                </small>
                <br />
                <small style={{ fontSize: "11.52px" }}>
                  Code: {props.codes}
                </small>
              </div>
              <div style={{ padding: 10 }}>
                <QRCode
                  value={props.codes}
                  style={{ width: 60, height: 60 }}
                  level="Q"
                />
              </div>
            </div>
            {/* {merchant.origin === "China" && supplierSetting.LOGISTIC_SHIPPER && (
              <small>{"SEND TO: " + supplierSetting.LOGISTIC_SHIPPER[0].address}</small>
            )} */}
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <button onClick={onPrintClicked} ref={props.refButton}>
          Print
        </button>
      </div>
      <iframe
        style={{
          position: "fixed",
          width: 1,
          height: 1,
          left: -1000,
          top: -1000,
        }}
        srcDoc={doc}
        ref={iframeRef}
        title="myframe"
      ></iframe>
    </div>
  );
}
